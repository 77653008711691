<template>
<div class="services">
    <b-row class="service" no-gutters>
        <b-col cols="12" md="6" order="2" order-md="1">
            <div class="service-description">
                <div class="text-center h4">
                    <inline-svg class="mr-2" :src="svg('pen-tool.svg')" width="30" height="30" />
                    <span>Ritningar</span>
                </div>

                <p class="pt-2">
                    Är du i behov av byggritningar? Vi levererar alltid hög kvalité!
                </p>
            </div>
        </b-col>
        <b-col cols="12" md="6" order="1" order-md="2">
            <div class="service-image">
                <b-img class="w-100" :src="image('plans.jpg')" />
            </div>
        </b-col>
    </b-row>
    <b-row class="service" no-gutters>
        <b-col cols="12" md="6">
            <div class="service-image">
                <b-img class="w-100" :src="image('clipboard.jpg')" />
            </div>
        </b-col>
        <b-col cols="12" md="6">
            <div class="service-description">
                <div class="text-center h4">
                    <inline-svg class="mr-2" :src="svg('clipboard.svg')" width="30" height="30" />
                    <span>Kontrollansvar, KA</span>
                </div>

                <p class="pt-2">
                    Ska du bygga och behöver du en certifierad kontrollansvarig? Då har du hittat rätt! Vi hjälper dig och ser till
                    att bygget uppfyller alla krav enligt bygglagstiftningen.
                </p>
                <p>
                    Vi finns med dig hela vägen under bygget! Vi tar fram ett förslag på kontrollplan, vi ser till att kontrollplanen håller,
                    deltar i tekniska samrådet samt besiktningen och övriga kontroller.
                </p>
                <p>
                    Vill du veta mer? <router-link variant="btn-primary" to="/kontakt">Kontakta oss</router-link>
                </p>
            </div>
        </b-col>

    </b-row>
    <b-row class="service" no-gutters>
        <b-col cols="12" md="6" order="2" order-md="1">
            <div class="service-description">
                <div class="text-center h4">
                    <inline-svg class="mr-2" :src="svg('trending-up.svg')" width="30" height="30" />
                    <span>Redovisningstjänster</span>
                </div>

                <p class="pt-2">
                    Vi erbjuder olika redovisningstjänster till dig som företagare. Behöver du hjälp med bokslutet,
                    eller kanske bara vill ha en genomgång av ditt bolags redovisning för att kontrollera att allt ser bra ut?
                </p>
                <p>
                    Har du svårt med att förstå momsrapporten eller behöver hjälp med att med nyckeltal få bästa vägen framåt för just ditt bolag?
                </p>

                Ex på priser
                <b-row class="text-center font-weight-bold">
                    <b-col>Tjänst</b-col>
                    <b-col>Pris ex moms</b-col>
                </b-row>
                <b-row class="text-center">
                    <b-col>Bokslut aktiebolag</b-col>
                    <b-col>5 900 SEK*</b-col>
                </b-row>
                <b-row class="text-center">
                    <b-col>Bokslut enskild firma</b-col>
                    <b-col>3 900 SEK*</b-col>
                </b-row>
                <p class="font-italic text-secondary mt-2" style="font-size:smaller">*Priserna bygger på att bokföringen är korrekt. Vid ev justeringar tillkommer ett timpris om 699 SEK/h ex moms</p>
            </div>
        </b-col>
        <b-col cols="12" md="6" order="1" order-md="2">
            <div class="service-image">
                <b-img class="w-100" :src="image('economy.jpg')" />
            </div>
        </b-col>
    </b-row>
</div>
</template>

<script>
export default {
    name: 'Services',
    components: {},
    data() {
        return {

        };
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        image(path) {
            return this.$images.pageImage(path);
        },
        svg(path) {
            return this.$images.svg(path);
        }
    }
};
</script>
